import {productConfigIterator} from 'components/customer/Product/helpers/productConfigIterator';
import {
    Structure,
    Drawer,
    GenericObject,
} from 'components/customer/Product/entity';
import {MATERIAL_NAMES} from 'components/customer/Materials/helper/constants';
import {cloneDeep} from 'lodash';
import {FIELD_TYPES} from 'shared';
import {isNumericField} from 'shared/validator';
import {CurrentInnerDrawer} from 'components/customer/Product/entity/Drawer';

export const getInitialValues = (productDetails: Structure[]) => {
    const materialNames = Object.values(MATERIAL_NAMES);
    const materials = materialNames.reduce(
        (acc, cur) => ((acc[cur] = -1), acc),
        {}
    );
    const initialValues: GenericObject = cloneDeep(materials);

    // CREATE INITIAL VALUES FROM productDetails.productConfig then check if productData exists > true map saved values > false do nothing
    const configIterator = productConfigIterator(productDetails);

    let config;
    while ((config = configIterator.next())) {
        if (typeof config.value === 'undefined') break;

        const {field, fieldset} = config.value;

        if (
            field.name === 'drawer_runner_specs' &&
            field.options.hasHeightFields
        ) {
            initialValues['drawers'] = [] as Drawer[];

            for (let i = 0; i < fieldset.quantity; i++) {
                initialValues['drawers'].push({
                    [field.name]: '',
                    [field.options.drawerTypeFieldName]: -1,
                    [field.options.drawerStyleFieldName]: 1,
                    [field.options.drawerHeightFieldName]: 0,
                } as Drawer);
            }
        } else if (field.name === 'inner_drawer_runner_specs') {
            initialValues['current_inner_drawers'] = [] as CurrentInnerDrawer[];

            for (let i = 0; i < fieldset.quantity; i++) {
                initialValues['current_inner_drawers'].push({
                    [field.name]: '',
                    [field.options.drawerTypeFieldName]: -1,
                    [field.options.drawerHeightFieldName]: 0,
                    position_to_bottom: 0,
                    inner_bottom_clearance: -1,
                    drawer_height: 0,
                    index: i,
                } as CurrentInnerDrawer);
            }
        } else if (field.name === 'cabinet_cover_void') {
            initialValues['cabinet_cover_void'] = false;
        } else {
            let fieldValue = field['value'];

            if (fieldValue == null) {
                switch (field.type) {
                    case FIELD_TYPES.BOOLEAN:
                        fieldValue = true;
                        break;

                    case FIELD_TYPES.SIZE:
                        fieldValue = 0;
                        break;

                    case FIELD_TYPES.HINGE_STYLE: // remove this later
                        fieldValue = 0;
                        break;
                }
            }

            initialValues[field['name']] = isNumericField(field.type)
                ? fieldValue
                    ? fieldValue
                    : 0
                : fieldValue;

            if (field.hasOwnProperty('options')) {
                Object.keys(field.options).forEach((key) => {
                    if (key.endsWith('Name')) {
                        const fieldName = field.options[String(key)] as string;
                        initialValues[String(fieldName)] = -1;
                    }
                });
            }
        }
    }

    return initialValues;
};
