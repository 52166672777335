import React from 'react';
import {Col, Image, Row} from 'react-bootstrap';
import {DrawerParameters} from 'components/customer/Product/entity/DrawerParameters';
import {InnerDrawerType} from 'components/customer/Product/InnerDrawer/InnerDrawerType';
import {InnerDrawerRunner} from 'components/customer/Product/InnerDrawer/InnerDrawerRunner';
import {InnerRunnerPreview} from 'components/customer/Product/InnerDrawer/InnerRunnerPreview';
import {useInnerDrawerTypeAndRunners} from 'components/customer/Product/InnerDrawer/helpers/useInnerDrawer';
import {InnerDrawerPositionToBottom} from 'components/customer/Product/InnerDrawer/InnerDrawerPositionToBottom';

interface DrawerTypeProps {
    runnerIndex: number;
    showLine: boolean;
    drawerParams: DrawerParameters;
}

export const InnerDrawer = ({
    runnerIndex,
    showLine = true,
    drawerParams,
}: DrawerTypeProps): JSX.Element => {
    const {
        availableInnerDrawerSystems,
        name,
        runnerName,
        runners,
        isFetching,
        isLoading,
        errorMessage,
        currentRunner,
    } = useInnerDrawerTypeAndRunners({
        runnerIndex,
        runnerFieldName: drawerParams.drawerRunnerFieldName,
        drawerTypeFieldName: drawerParams.drawerTypeFieldName,
    });

    return (
        <>
            <Row className="drawer-runner">
                <Col md={7} xs={12}>
                    <strong className="compound_title">
                        Drawer #{runnerIndex + 1}
                    </strong>
                    <InnerDrawerType
                        fieldName={name}
                        availableInnerDrawerSystems={
                            availableInnerDrawerSystems
                        }
                    />
                    <InnerDrawerRunner
                        errorMessage={errorMessage}
                        runnerName={runnerName}
                        runners={runners}
                        isLoading={isLoading}
                        isFetching={isFetching}
                    />
                    <InnerDrawerPositionToBottom
                        label="Position to Bottom"
                        name={`position_to_bottom`}
                        runnerIndex={runnerIndex}
                    />
                </Col>
                <Col md={2} xs={6}>
                    <InnerRunnerPreview
                        runnerIndex={runnerIndex}
                        totalDrawerHeight={drawerParams.totalDrawerHeight}
                        drawerGapFieldName={drawerParams.drawerGapFieldName}
                        drawerTopMarginFieldName={
                            drawerParams.drawerTopMarginFieldName
                        }
                        drawerBottomMarginFieldName={
                            drawerParams.drawerBottomMarginFieldName
                        }
                    />
                </Col>
                <Col md={3} xs={6}>
                    <section className="drawer-thumbnail">
                        <Image src={`${currentRunner?.image}`} rounded />
                    </section>
                </Col>
            </Row>
            {showLine ? <hr /> : null}
        </>
    );
};
