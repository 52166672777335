import React, {useEffect, useMemo, useRef} from 'react';

import type {
    Field as FieldType,
    DrawerFieldset as Fieldset,
} from 'shared/types';
import {InnerDrawer} from 'components/customer/Product/InnerDrawer/InnerDrawer';
import {CurrentInnerDrawer} from 'components/customer/Product/entity';
import {useProductContext} from 'contexts';
import {
    useInnerDrawer,
    calculateDrawerPositions,
} from 'components/customer/Product/InnerDrawer/helpers/useInnerDrawer';
import {isEqual} from 'lodash';

interface InnerDrawerRunnerType {
    field: FieldType;
    fieldset: Fieldset;
    reducedLabelColumnWidth?: boolean;
}

interface MaterialOptions {
    cabinet_carc_colour: {
        thickness: number;
    };
}

export const InnerDrawerRunner = ({
    field,
    fieldset,
}: InnerDrawerRunnerType): JSX.Element => {
    const {drawerParams, values, setFieldValue} = useInnerDrawer({
        field,
        fieldset,
    });

    const prevInnerDrawersRef = useRef<CurrentInnerDrawer[]>([]);
    const {getMaterialOptions} = useProductContext<{
        getMaterialOptions: () => MaterialOptions;
    }>();

    useEffect(() => {
        if (
            !isEqual(values.current_inner_drawers, prevInnerDrawersRef.current)
        ) {
            const updatedDrawers = values.current_inner_drawers.map(
                (drawer) => {
                    const materialOptions = getMaterialOptions();
                    const positions =
                        calculateDrawerPositions(
                            values.cabinet_height,
                            materialOptions.cabinet_carc_colour.thickness,
                            values.current_inner_drawers
                        ) || [];

                    const pos = positions?.find((p) => p.index == drawer.index);

                    return {
                        ...drawer,
                        position_to_bottom:
                            pos?.position_to_bottom ||
                            drawer.position_to_bottom,
                        isInvalid: pos?.isInvalid || false,
                        maxPosition: pos?.maxPosition || 0,
                        minPosition: pos?.minPosition || 0,
                        errorMessage: pos?.errorMessage || '',
                    };
                }
            );

            void setFieldValue('current_inner_drawers', updatedDrawers);

            prevInnerDrawersRef.current = updatedDrawers;
        }
    }, [values.current_inner_drawers]);

    return useMemo<JSX.Element>(() => {
        if (drawerParams) {
            return (
                <>
                    {values.current_inner_drawers.map((drawer, index) => (
                        <InnerDrawer
                            key={drawer.index}
                            runnerIndex={index}
                            drawerParams={drawerParams}
                            showLine={
                                values.current_inner_drawers.length !==
                                index + 1
                            }
                        />
                    ))}
                </>
            );
        }

        return null;
    }, [drawerParams]);
};
