import {Product} from 'components/customer/Product/entity/Product';
import {ProductApi} from 'components/customer/Product/entity/ProductApi';

export const mapProduct = (product: ProductApi): Product => {
    return {
        appliance_id: Number(product.appliance_id),
        applied_panel_depth: Number(product.applied_panel_depth),
        cabinet_applied_panel_depth: Number(product.applied_panel_depth),
        cabinet_adjustable_legs: Number(product.adjustable_legs),
        cabinet_carc_colour: Number(product.carc_colour),
        cabinet_carc_thickness: Number(product.carcase_material_thickness),
        cabinet_carc_edge_colour: Number(product.carc_edge_colour),
        cabinet_comment: product.comment,
        cabinet_depth: Number(product.depth),
        cabinet_door: Number(product.door_style),
        cabinet_door_bottom: Number(product.door_bottom),
        cabinet_door_gap: Number(product.door_gap),
        cabinet_door_left: Number(product.door_left),
        cabinet_door_name: product.door_name,
        cabinet_door_right: Number(product.door_right),
        cabinet_door_top: Number(product.door_top),
        cabinet_drawer_bottom: Number(product.drawer_bottom),
        cabinet_drawer_gap: Number(product.drawer_gap),
        cabinet_drawer_left: Number(product.drawer_left),
        cabinet_drawer_right: Number(product.drawer_right),
        cabinet_drawer_top: Number(product.drawer_top),
        cabinet_cover_void: Number(product.cover_void),
        cabinet_edge_inner: Number(product.edge_inner),
        cabinet_edge_l1: Number(product.edge_l1),
        cabinet_edge_l2: Number(product.edge_l2),
        cabinet_edge_w1: Number(product.edge_w1),
        cabinet_edge_w2: Number(product.edge_w2),
        cabinet_exclude_hardware: Number(product.exclude_hardware),
        cabinet_ext_brand_name: product.exterior_material_brand_name,
        cabinet_ext_colour: Number(product.ext_colour),
        cabinet_ext_finish: product.exterior_material_finish,
        cabinet_ext_substrate: product.exterior_material_substrate,
        cabinet_ext_edge_colour: Number(product.ext_edge_colour),
        cabinet_ext_radius: Number(product.ext_radius),
        cabinet_ext_thickness: Number(product.exterior_material_thickness),
        cabinet_extend: Number(product.extend_to_floor),
        cabinet_height: Number(product.height),
        cabinet_include_assembly: Number(product.include_assembly),
        cabinet_include_hardware: Number(product.exclude_hardware) == 1 ? 0 : 1,
        cabinet_int_radius: Number(product.int_radius),
        cabinet_ladder_frames: Number(product.ladder_frames),
        cabinet_length_depth: Number(product.length_depth),
        cabinet_length1: Number(product.length1),
        cabinet_length2: Number(product.length2),
        cabinet_mirror: Number(product.mirror),
        cabinet_note: product.note,
        cabinet_quantity: Number(product.quantity),
        cabinet_return_panel_width: Number(product.return_panel_width),
        cabinet_style: Number(product.type),
        cabinet_toekick: Number(product.toe_kick_height),
        cabinet_top: Number(product.top),
        cabinet_total_drawer_height: Number(product.total_drawer_height),
        cabinet_type: Number(product.type),
        cabinet_upper_filler_depth: Number(product.upper_filler_depth),
        cabinet_void_width: Number(product.void_width),
        cabinet_width: Number(product.width),
        cabinet_width_depth: Number(product.width_depth),
        cabinet_width1: Number(product.width1),
        cabinet_width_door_1: Number(product.width1),
        carc_area_assembly_cost: Number(product.carc_area_cost),
        carc_area_cost: Number(product.carc_area_cost),
        carc_colour: Number(product.carc_colour),
        carc_custom_colour: product.carc_custom_colour,
        carc_double_sided: Number(product.carc_double_sided),
        carc_double_sided_cost: Number(product.carc_double_sided_cost),
        carc_edge: {
            id: Number(product.carc_edge_colour),
            image: product.carcase_edge_image,
        },
        carc_edge_colour: Number(product.carc_edge_colour),
        carc_hor_grain: Number(product.carc_hor_grain),
        carc_material: {
            id: Number(product.carc_colour),
            name: product.carcase_material_name,
            image: product.carcase_material_image,
            finish: product.carcase_material_finish,
            doorFilter: product.carcase_material_door_filter,
            door_filter: product.carcase_material_door_filter,
            thickness: Number(product.carcase_material_thickness),
            type_name: product.carcase_material_type_name,
            typeName: product.carcase_material_type_name,
            substrate: product.carcase_material_substrate,
            brandName: product.carcase_material_brand_name,
            brand_name: product.carcase_material_brand_name,
            double_sided: Number(product.carc_double_sided),
            doubleSided: Number(product.carc_double_sided) == 1,
            is_double_sided: Number(product.carc_double_sided) == 1,
            hor_grain: Number(product.carc_hor_grain),
            is_grained: Number(product.carc_hor_grain) == 1,
            horGrain: Number(product.carc_hor_grain) == 1,
            customColour: product.carcase_material_name
                .toLowerCase()
                .includes('custom colour'),
            type_id: Number(product.carcase_material_type_id),
            typeId: Number(product.carcase_material_type_id),
        },
        carcase_colour_name: product.carcase_material_name,
        cabinet_carc_finish: product.carcase_material_finish,
        cabinet_carc_brand_name: product.carcase_material_brand_name,
        cabinet_carc_substrate: product.carcase_material_substrate,
        comment: product.comment,
        cost: Number(product.cost),
        cover_void: Number(product.cover_void),
        custom_colour_carc: product.carc_custom_colour,
        custom_colour_ext: product.ext_custom_colour,
        depth: Number(product.depth),
        door_bottom: Number(product.door_bottom),
        door_gap: Number(product.door_gap),
        door_hinge_amount: Number(product.door_hinge_amount),
        door_left: Number(product.door_left),
        door_right: Number(product.door_right),
        door_style: Number(product.door_style),
        door_suffix: product.door_suffix,
        door_suffix_id: Number(product.door_suffix_id),
        door_top: Number(product.door_top),
        door: {
            id: Number(product.door_id),
            name: product.door_name,
            filter_name: product.door_filter,
            filterName: product.door_filter,
            suffix: product.door_suffix,
            suffix_id: Number(product.door_suffix_id),
            suffix_name: product.door_suffix_name,
            image: product.door_image_name,
        },
        dimensions: product.dimensions,
        double_sided_carc: Number(product.carc_double_sided),
        double_sided_ext: Number(product.ext_double_sided) == 1,
        drawer_amount: Number(product.drawer_amount),
        drawer_bottom: Number(product.drawer_bottom),
        drawer_face_type: Number(product.drawer_face_type),
        drawer_gap: Number(product.drawer_gap),
        drawer_left: Number(product.drawer_left),
        drawer_right: Number(product.drawer_right),
        drawer_top: Number(product.drawer_top),
        edge_inner: Number(product.edge_inner),
        edge_l1: Number(product.edge_l1),
        edge_l2: Number(product.edge_l2),
        edge_w1: Number(product.edge_w1),
        edge_w2: Number(product.edge_w2),
        exclude_hardware: Number(product.exclude_hardware),
        ext_colour: Number(product.ext_colour),
        ext_custom_colour: product.ext_custom_colour,
        ext_double_sided: Number(product.ext_double_sided),
        ext_edge: {
            id: Number(product.ext_edge_colour),
            image: product.exterior_edge_image,
        },
        ext_edge_colour: Number(product.ext_edge_colour),
        ext_hor_grain: Number(product.ext_hor_grain),
        ext_material: {
            id: Number(product.ext_colour),
            name: product.exterior_material_name,
            image: product.exterior_material_image,
            finish: product.exterior_material_finish,
            doorFilter: product.exterior_material_door_filter,
            door_filter: product.exterior_material_door_filter,
            thickness: Number(product.exterior_material_thickness),
            type_name: product.exterior_material_type_name,
            typeName: product.exterior_material_type_name,
            substrate: product.exterior_material_substrate,
            brandName: product.exterior_material_brand_name,
            brand_name: product.exterior_material_brand_name,
            double_sided: Number(product.ext_double_sided),
            doubleSided: Number(product.ext_double_sided) == 1,
            is_double_sided: Number(product.ext_double_sided) == 1,
            hor_grain: Number(product.ext_hor_grain),
            is_grained: Number(product.ext_hor_grain) == 1,
            horGrain: Number(product.ext_hor_grain) == 1,
            customColour: product.exterior_material_name
                .toLowerCase()
                .includes('custom colour'),
            type_id: Number(product.exterior_material_type_id),
            typeId: Number(product.exterior_material_type_id),
        },
        exterior_colour_name: product.exterior_material_name,
        extend_to_floor: Number(product.extend_to_floor),
        height: Number(product.height),
        hinge_style: Number(product.hinge_style),
        hor_grain_carc: Number(product.carc_hor_grain) == 1,
        hor_grain_ext: Number(product.ext_hor_grain) == 1,
        hori_shelf_amount: Number(product.hori_shelf_amount),
        include_assembly: Number(product.include_assembly),
        include_hardware: Number(product.exclude_hardware) == 1 ? 0 : 1,
        id: Number(product.id),
        in_qfp_summary: Number(product.in_qfp_summary),
        int_radius: Number(product.int_radius),
        job_cabinet_id: Number(product.id),
        ladder_frames: Number(product.ladder_frames),
        length_depth: Number(product.length_depth),
        length1: Number(product.length1),
        length2: Number(product.length2),
        material_prefix: product.material_prefix,
        mirror: Number(product.mirror),
        note: product.note,
        quantity: Number(product.quantity),
        room_cab_number: Number(product.room_cab_number),
        room_id: Number(product.room_id),
        return_panel_width: Number(product.return_panel_width),
        toe_kick_height: Number(product.toe_kick_height),
        top: Number(product.top),
        total_drawer_height: Number(product.total_drawer_height),
        type: Number(product.type),
        type_image: product.cabinet_image,
        type_name: product.cabinet_type_name,
        type_image_3d: product.cabinet_3d_image,
        upper_filler_depth: Number(product.upper_filler_depth),
        upper_partition_height: Number(product.upper_partition_height),
        variation_cost: Number(product.variation_cost),
        variation_id: Number(product.variation_id),
        vert_shelf_amount: Number(product.vert_shelf_amount),
        void_width: Number(product.void_width),
        width: Number(product.width),
        width_depth: Number(product.width_depth),
        width1: Number(product.width1),
        width2: Number(product.width2),
        finger_pull: Number(product.finger_pull),
        dimension_fields: product.dimension_fields,
        description: product.cabinet_description,
    };
};
