import {useState, useEffect, useMemo, useRef} from 'react';
import {useFormikContext} from 'formik';
import {useNotificationContext, useProductContext} from 'contexts';
import {isEqual, toNumber} from 'lodash';
import type {
    Field as FieldType,
    DrawerFieldset as Fieldset,
} from 'shared/types';
import {CurrentInnerDrawer} from 'components/customer/Product/entity';
import {
    DrawerRunnerRequest,
    useGetDrawerSystemQuery,
    useLazyGetRunnerQuery,
} from 'components/customer/Product/store/drawerRunnerApi';
import {
    CurrentInnerDrawerFields,
    InnerDrawerKey,
} from 'components/customer/Product/entity/Drawer';
import {useSearchParams} from 'react-router-dom';
import {validationErrorAppliesToField} from 'shared/validator';
import {DrawerRunner} from 'components/customer/Product/entity/DrawerRunner';

interface InnerDrawerParameters {
    quantity?: number;
    drawerRunnerFieldName?: string;
    drawerTypeFieldName?: string;
    drawerGapFieldName?: string;
    drawerTopMarginFieldName?: string;
    drawerBottomMarginFieldName?: string;
    totalDrawerHeight?: string;
}

interface InnerDrawerTypeAndRunners {
    runnerIndex: number;
    drawerTypeFieldName: string;
    runnerFieldName: string;
}

export const calculateDrawerPositions = (
    cabinetHeight: number,
    carcaseThickness: number,
    drawers: CurrentInnerDrawer[]
) => {
    const positions: CurrentInnerDrawer[] = [];

    // Calculate the available height
    const availableHeight = cabinetHeight - carcaseThickness * 2;
    let isInvalid = false;
    let errorMessage = '';

    // Calculate total required height for drawers and clearances
    const totalRequiredHeight = drawers
        .slice()
        .reverse()
        .reduce((acc, drawer, index) => {
            const topClearance = drawer.top_clearance || 0;
            const bottomClearance =
                index === 0 ? drawer.inner_bottom_clearance || 0 : 0;
            return acc + drawer.drawer_height + topClearance + bottomClearance;
        }, 0);

    if (totalRequiredHeight > availableHeight) {
        isInvalid = true;
        errorMessage = 'Total drawers do not fit in the cabinet.';
    }

    drawers
        .slice()
        .reverse()
        .forEach((drawer, index) => {
            let positionToBottom = drawer.position_to_bottom;
            let maxPosition = availableHeight;
            let minPosition = 0;

            // last drawer
            if (index === drawers.length - 1) {
                maxPosition = Math.min(
                    maxPosition,
                    availableHeight - drawer.drawer_height
                );
            }

            if (index > 0) {
                const prevDrawer = positions[index - 1];
                minPosition =
                    prevDrawer.position_to_bottom +
                    prevDrawer.drawer_height +
                    prevDrawer.top_clearance;

                minPosition = Math.min(
                    minPosition,
                    availableHeight - drawer.drawer_height
                );

                positionToBottom = minPosition;
            }

            // Calculate maxPosition based on the remaining drawers
            for (let i = index + 1; i < drawers.length; i++) {
                const nextDrawer = drawers[Number(i)];
                maxPosition -=
                    nextDrawer.drawer_height + nextDrawer.top_clearance;
            }

            // Validate the current position_to_bottom
            if (
                positionToBottom < minPosition ||
                positionToBottom > maxPosition
            ) {
                // Adjust if the current value is out of bounds
                positionToBottom = Math.max(
                    minPosition,
                    Math.min(positionToBottom, maxPosition)
                );
            }

            // If this is the first drawer, ensure it respects its inner_bottom_clearance
            if (index == 0) {
                minPosition = drawer.inner_bottom_clearance;
                positionToBottom = minPosition;
            }

            const postionToBottomValue =
                drawer?.manualPositionToBottom || positionToBottom;

            if (!isInvalid) {
                isInvalid =
                    postionToBottomValue < minPosition ||
                    postionToBottomValue > maxPosition;
            }

            positions.push({
                position_to_bottom: postionToBottomValue,
                drawer_height: drawer.drawer_height,
                top_clearance: drawer.top_clearance,
                index: drawer.index,
                isInvalid,
                maxPosition,
                minPosition,
                errorMessage,
            });
        });

    return positions;
};

export const useInnerRunnerRequestParams = (
    runnerIndex: number,
    drawerTypeFieldName: string
) => {
    const {values} = useFormikContext<CurrentInnerDrawerFields>();
    const [searchParams] = useSearchParams();

    const [params, setParams] = useState<DrawerRunnerRequest>({});

    const drawerRunnerProps = useRef<DrawerRunnerRequest>({});
    const getInnerDrawerRunnerProps = (): DrawerRunnerRequest => {
        const drawer = values.current_inner_drawers[Number(runnerIndex)];

        return {
            insertType: toNumber(drawer[drawerTypeFieldName as InnerDrawerKey]),
            cabinetId: parseInt(searchParams.get('product')),
        };
    };

    useEffect(() => {
        const runnerProps = getInnerDrawerRunnerProps();

        if (!isEqual(runnerProps, drawerRunnerProps.current)) {
            drawerRunnerProps.current = runnerProps;

            setParams(runnerProps);
        }
    }, [values]);

    return {
        params,
    };
};

export const useInnerDrawer = ({
    field,
    fieldset,
}: {
    field: FieldType;
    fieldset: Fieldset;
}) => {
    const {values, setFieldValue} = useFormikContext<{
        inner_drawer_amount: number;
        current_inner_drawers: CurrentInnerDrawer[];
        cabinet_height: number;
        [key: string]: number | CurrentInnerDrawer[];
    }>();

    const drawerParams = useMemo<InnerDrawerParameters>(() => {
        const params: InnerDrawerParameters = {
            drawerGapFieldName: '',
            totalDrawerHeight: '',
            drawerTopMarginFieldName: 'cabinet_drawer_top',
            drawerBottomMarginFieldName: 'cabinet_drawer_bottom',
        };

        if (field) {
            params.drawerRunnerFieldName = field.name;
            params.drawerTypeFieldName = field.options.drawerTypeFieldName;
        }

        if (fieldset && fieldset.quantity) {
            params.quantity = values.inner_drawer_amount;
            params.drawerGapFieldName = fieldset.options.drawerGap;
            params.totalDrawerHeight = fieldset.options.totalDrawerHeight;
        }

        return params;
    }, [field, fieldset, values]);

    useEffect(() => {
        let updatedDrawers = [...values.current_inner_drawers];

        if (updatedDrawers.length < drawerParams.quantity) {
            while (updatedDrawers.length < drawerParams.quantity) {
                updatedDrawers.push({
                    id: 0,
                    index: updatedDrawers.length,
                    position_to_bottom: 0,
                    inner_drawer_runner_specs: '',
                    insert_id: -1,
                });
            }
        } else if (updatedDrawers.length > drawerParams.quantity) {
            updatedDrawers = updatedDrawers.slice(0, drawerParams.quantity);
        }

        if (updatedDrawers.length !== values.current_inner_drawers.length) {
            void setFieldValue('current_inner_drawers', updatedDrawers);
        }
    }, [drawerParams.quantity, values.current_inner_drawers.length]);

    return {
        drawerParams,
        values,
        setFieldValue,
    };
};

export const useInnerDrawerTypeAndRunners = ({
    runnerIndex,
    drawerTypeFieldName,
    runnerFieldName,
}: InnerDrawerTypeAndRunners) => {
    const {getAvailableInnerDrawers} = useProductContext<{
        getAvailableInnerDrawers: () => number[];
    }>();
    const {data: drawerSystems} = useGetDrawerSystemQuery();
    const {values, setFieldValue} =
        useFormikContext<CurrentInnerDrawerFields>();
    const {params} = useInnerRunnerRequestParams(
        runnerIndex,
        drawerTypeFieldName
    );
    const [runners, setRunners] = useState<DrawerRunner[]>([]);
    const {messages} = useNotificationContext();
    const [getRunners, {isFetching, isLoading}] = useLazyGetRunnerQuery();

    const drawerAmount = values.current_inner_drawers.length;
    const currentInnerDrawer =
        values.current_inner_drawers[Number(runnerIndex)];

    const availableInnerDrawerSystems = useMemo(() => {
        if (drawerSystems) {
            const availableInnerDrawers = getAvailableInnerDrawers();
            const isDrillOnly = values.cabinet_include_hardware === 0;

            return drawerSystems
                .filter((drawerSystem) =>
                    availableInnerDrawers.includes(drawerSystem.id)
                )
                .map((drawerSystem) => ({
                    ...drawerSystem,
                    disabled: !isDrillOnly && drawerSystem.drillOnly,
                }));
        }
        return [];
    }, [drawerSystems, values.cabinet_include_hardware]);

    const name = useMemo(
        () => `current_inner_drawers[${runnerIndex}.${drawerTypeFieldName}]`,
        [drawerTypeFieldName, runnerIndex]
    );

    const runnerName = useMemo(
        () => `current_inner_drawers[${runnerIndex}.${runnerFieldName}]`,
        [runnerFieldName, runnerIndex]
    );

    const value = useMemo(() => {
        const drawer = values.current_inner_drawers[Number(runnerIndex)];

        return drawer[runnerFieldName as InnerDrawerKey];
    }, [values]);

    const currentRunner = useMemo(() => {
        return runners?.find((option) => option.id === value);
    }, [runners, value]);

    const currentDrawerSystem = useMemo(() => {
        const drawer = values.current_inner_drawers[Number(runnerIndex)];

        const drawerTypeValue = drawer[drawerTypeFieldName as InnerDrawerKey];

        return availableInnerDrawerSystems?.find(
            (option) => option.id == drawerTypeValue
        );
    }, [availableInnerDrawerSystems, values.current_inner_drawers]);

    const triggerGetRunners = async (runnerProps: DrawerRunnerRequest) => {
        const {data} = await getRunners(runnerProps, true);
        const filteredData = data?.filter((runner) => runner.innerUnitCost > 0);
        setRunners(filteredData);
        if (filteredData?.length === 0) {
            void setFieldValue(runnerName, -1);
        }
    };

    const errorMessage = useMemo(() => {
        const errors = messages?.errors;

        if (errors && Array.isArray(errors)) {
            const runnerErrors = errors.filter((error) =>
                validationErrorAppliesToField(
                    error,
                    'inner_drawer_runner_specs',
                    runnerIndex
                )
            );

            return runnerErrors.find(({fields}) =>
                fields.includes(`${runnerFieldName}[${runnerIndex}]`)
            )?.message;
        }
        return null;
    }, [messages, currentInnerDrawer]);

    useEffect(() => {
        if (params) {
            void triggerGetRunners(params);
        }
    }, [params, values.current_inner_drawers]);

    useEffect(() => {
        if (runners?.length && !currentRunner) {
            void setFieldValue(runnerName, runners[0].id);
        }
    }, [runners, currentRunner, value]);

    useEffect(() => {
        const innerDrawerBottomClearance =
            currentDrawerSystem?.innerBottomClearance || 0;
        const topClearance = currentDrawerSystem?.topClearance || 0;
        const drawerHeight = currentRunner?.runnerHeight || 0;

        const updatedDrawer = {
            ...currentInnerDrawer,
            inner_bottom_clearance: innerDrawerBottomClearance,
            top_clearance: topClearance,
            drawer_height: drawerHeight,
        };

        if (!isEqual(updatedDrawer, currentInnerDrawer)) {
            void setFieldValue(
                `current_inner_drawers[${runnerIndex}]`,
                updatedDrawer
            );
        }
    }, [
        runnerIndex,
        currentRunner,
        currentDrawerSystem,
        currentInnerDrawer,
        drawerAmount,
    ]);

    return {
        values,
        availableInnerDrawerSystems,
        name,
        runnerName,
        runners,
        currentDrawerSystem,
        currentRunner,
        isFetching,
        isLoading,
        errorMessage,
        drawerAmount: values.current_inner_drawers.length,
        currentInnerDrawer: values.current_inner_drawers[Number(runnerIndex)],
    };
};
