import React, {useMemo, useRef} from 'react';
import {useFormikContext} from 'formik';
import {useProductContext} from 'contexts';
import excel from 'shared/Excel';
import {CurrentInnerDrawerFields} from 'components/customer/Product/entity/Drawer';
import {toNumber} from 'components/customer/Product/Drawer/helpers';
import {useInView} from 'react-intersection-observer';
import {CSSProperties} from 'styled-components';
import {DrawerFace} from 'components/customer/Product/Drawer/DrawerFace';

interface RunnerPreviewTypes {
    runnerIndex: number;
    totalDrawerHeight: string;
    drawerGapFieldName: string;
    drawerTopMarginFieldName: string;
    drawerBottomMarginFieldName: string;
}

interface RunnerPreviewInterface {
    totalDrawerHeight: number;
    drawerGap: number;
    drawerTopMargin: number;
    drawerBottomMargin: number;
    color?: string;
}

interface DrawerFacesInterface {
    topMargin: number;
    bottomMargin: number;
    drawers: JSX.Element[];
}

export const InnerRunnerPreview = ({
    runnerIndex,
    totalDrawerHeight,
    drawerGapFieldName,
    drawerTopMarginFieldName,
    drawerBottomMarginFieldName,
}: RunnerPreviewTypes) => {
    const {ref: previewContainer, inView} = useInView({
        threshold: 0,
    });
    const previewElement = useRef<HTMLDivElement>();
    const {values} = useFormikContext<CurrentInnerDrawerFields>();
    const {getMaterialOptions} = useProductContext<{
        getMaterialOptions: () => {
            exterior_color: string;
        };
    }>();

    const runnerPreviewParams = useMemo<RunnerPreviewInterface>(() => {
        const materials = getMaterialOptions();
        const totalHeight = excel.calculate<number>(totalDrawerHeight, {
            ...values,
            ...materials,
        });

        const params = {
            totalDrawerHeight: totalHeight,
            drawerGap: toNumber(values[String(drawerGapFieldName)]),
            drawerTopMargin: toNumber(values[String(drawerTopMarginFieldName)]),
            drawerBottomMargin: toNumber(
                values[String(drawerBottomMarginFieldName)]
            ),
            color: '#3F72B9',
        };

        if (materials.exterior_color) {
            if (
                materials.exterior_color.indexOf(
                    'uploads/gocabinet_materials'
                ) == -1
            ) {
                params.color = `/uploads/gocabinet_materials/${materials.exterior_color}`;
            }

            params.color = `${materials.exterior_color}`;
        }

        return params;
    }, [values]);

    const {topMargin, bottomMargin, drawers} =
        useMemo<DrawerFacesInterface>(() => {
            if (inView) {
                const {drawerGap, drawerTopMargin, drawerBottomMargin, color} =
                    runnerPreviewParams;

                const availableHeight =
                    previewElement.current.getBoundingClientRect().height;

                const numberOfDrawers = values.current_inner_drawers.length;

                // Calculate total gaps and margins
                const totalGap = drawerGap * (numberOfDrawers - 1);
                const totalMargins = drawerTopMargin + drawerBottomMargin;

                // Calculate the height available for all drawers combined
                const drawersAvailableHeight =
                    availableHeight - totalGap - totalMargins;

                // Calculate the actual gap and margins based on ratio if needed
                const ratio =
                    availableHeight /
                    (drawersAvailableHeight + totalGap + totalMargins);

                const topMargin = Math.floor(drawerTopMargin * ratio);
                const bottomMargin = Math.floor(drawerBottomMargin * ratio);
                const gap = Math.floor(drawerGap * ratio);

                return {
                    topMargin,
                    bottomMargin,
                    drawers: values.current_inner_drawers.map(
                        (drawer, index) => {
                            // Since faceHeight is removed, use heightPerDrawer directly
                            // Calculate the height of each drawer based on positionToBottom

                            const drawerRatio =
                                availableHeight / drawer.drawer_height;

                            const drawerHeight = Math.floor(
                                (drawer.drawer_height -
                                    drawer.top_clearance -
                                    (index ===
                                    values.current_inner_drawers.length - 1
                                        ? drawer.inner_bottom_clearance
                                        : 0)) *
                                    drawerRatio
                            );

                            const scaledPositionToBottom =
                                ((drawer.position_to_bottom -
                                    drawer.minPosition) /
                                    (drawer.maxPosition - drawer.minPosition)) *
                                (drawerHeight / 2);

                            const style: CSSProperties = {
                                height: `${drawerHeight}px`,
                            };

                            let backgroundImage: string | undefined;
                            if (index === runnerIndex) {
                                backgroundImage = `url("${color}")`;
                            }

                            // Apply border styles
                            if (index >= 0) {
                                style.borderBottomWidth = '2px';
                                style.borderBottomStyle = 'solid';

                                if (index === 0 && topMargin > 0) {
                                    style.borderTopWidth = '2px';
                                    style.borderTopStyle = 'solid';
                                }
                            }

                            // Remove border bottom for the last drawer if needed
                            if (
                                index === numberOfDrawers - 1 &&
                                bottomMargin <= 0
                            ) {
                                style.borderBottomWidth = '0';
                            }

                            // Apply gap as marginTop for drawers after the first one
                            if (index > 0 && gap > 0) {
                                style.marginTop = `${gap}px`;
                                style.borderTopWidth = '2px';
                                style.borderTopStyle = 'solid';
                            }

                            const previewWidth =
                                previewElement.current.getBoundingClientRect()
                                    .width;
                            const size = Math.max(previewWidth, drawerHeight);

                            return (
                                <DrawerFace
                                    key={drawer.index}
                                    $backgroundImage={backgroundImage}
                                    $rotate={values.hor_grain_ext}
                                    $size={size}
                                    $hasTopMargin={topMargin > 0}
                                    $hasBottomMargin={bottomMargin > 0}
                                    className="drawer"
                                    style={style}>
                                    {index === runnerIndex ? (
                                        <div
                                            style={{
                                                position: 'absolute',
                                                bottom: `${scaledPositionToBottom}px`,
                                                left: '0',
                                                right: '0',
                                                height: '1px',
                                                backgroundColor: '#fab1a0',
                                            }}
                                        />
                                    ) : null}
                                </DrawerFace>
                            );
                        }
                    ),
                };
            }

            return {
                topMargin: 0,
                bottomMargin: 0,
                drawers: [<React.Fragment key={0} />],
            };
        }, [
            values.current_inner_drawers,
            runnerPreviewParams,
            inView,
            values.hor_grain_ext,
        ]);

    return (
        <div
            className="cabinet-shape-container"
            style={{
                height: '100%',
                maxHeight: '200px',
                background: '#e5e5e5',
                borderRadius: '8px',
                margin: '0 auto',
            }}
            ref={previewContainer}>
            <div className="leg-container left">
                <div className="leg" />
            </div>
            <div className="leg-container right">
                <div className="leg" />
            </div>

            <div
                style={{
                    height: '100%',
                    maxHeight: '200px',
                    paddingTop: `${topMargin}px`,
                    paddingBottom: `${bottomMargin}px`,
                }}
                className="cabinet-shape"
                ref={previewElement}>
                {drawers}
            </div>
        </div>
    );
};
