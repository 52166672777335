import React from 'react';
import {Col, Form} from 'react-bootstrap';
import SelectField from 'shared/components/SelectField';
import {WithTooltipWrapper} from 'components/manufacturer/shared/InputFieldWithTooltip';
import {DrawerRunner} from 'components/customer/Product/entity/DrawerRunner';
import {FormGroup} from 'shared/components/Forms/FormGroup';

interface DrawerRunnerProps {
    runnerName: string;
    errorMessage: string;
    runners: DrawerRunner[];
    labelColSpan?: number;
    isFetching: boolean;
    isLoading: boolean;
}

export const InnerDrawerRunner = ({
    errorMessage,
    runnerName,
    runners,
    isFetching,
    isLoading,
    labelColSpan = 5,
}: DrawerRunnerProps) => {
    return (
        <FormGroup>
            <Form.Label column md={labelColSpan} xs={12} style={{zIndex: 1}}>
                Runner
                <span>*</span>
            </Form.Label>
            <Col md={7} xs={12}>
                <WithTooltipWrapper
                    show={!!errorMessage}
                    tooltip={errorMessage}>
                    <SelectField
                        name={runnerName}
                        placeholder="No runners small enough to fit in cabinet depth"
                        options={runners?.map(({id, name}) => ({
                            label: name,
                            value: String(id),
                        }))}
                        isLoading={isFetching || isLoading}
                        invalid={!!errorMessage}
                        isDisabled={!!errorMessage}
                    />
                </WithTooltipWrapper>
            </Col>
        </FormGroup>
    );
};
