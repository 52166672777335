import React from 'react';
import {Col, Form} from 'react-bootstrap';

import SelectField from 'shared/components/SelectField';
import {DrawerSystem} from 'components/customer/Product/entity';
import {FormGroup} from 'shared/components/Forms/FormGroup';

interface InnerDrawerTypeProps {
    runnerIndex?: number;
    availableInnerDrawerSystems: DrawerSystem[];
    fieldName: string;
    labelColSpan?: number;
}

export const InnerDrawerType = ({
    fieldName,
    availableInnerDrawerSystems,
    labelColSpan = 5,
}: InnerDrawerTypeProps) => {
    return (
        <FormGroup>
            <Form.Label column md={labelColSpan} xs={12} style={{zIndex: 1}}>
                Type
                <span>*</span>
            </Form.Label>
            <Col md={7} xs={12}>
                <SelectField
                    name={fieldName}
                    defaultFirstValue={true}
                    options={availableInnerDrawerSystems?.map(
                        ({id, name, disabled}) => ({
                            label: name,
                            value: String(id),
                            disabled,
                        })
                    )}
                />
            </Col>
        </FormGroup>
    );
};
