import React, {useCallback} from 'react';
import {Form, Col} from 'react-bootstrap';
import {useFormikContext} from 'formik';
import {CurrentInnerDrawer} from 'components/customer/Product/entity/Drawer';
import {Size} from 'shared';
import {FormGroup} from 'shared/components/Forms/FormGroup';

export const InnerDrawerPositionToBottom = ({
    label,
    name,
    runnerIndex,
}: {
    label: string;
    name: string;
    runnerIndex: number;
}): JSX.Element => {
    const {values, setFieldValue} = useFormikContext<{
        current_inner_drawers: CurrentInnerDrawer[];
        cabinet_height: number;
        carcase_thickness: number;
        [key: string]: number | CurrentInnerDrawer[];
    }>();
    const innerDrawer = values.current_inner_drawers[Number(runnerIndex)];

    const selectHandler = useCallback(
        (name: string, value: number) => {
            void setFieldValue(
                `current_inner_drawers[${runnerIndex}].manualPositionToBottom`,
                value
            );
        },
        [values.current_inner_drawers]
    );

    return (
        <FormGroup controlId={name}>
            <Form.Label column md={5} xs={12}>
                {label}
            </Form.Label>
            <Col md={7} xs={12}>
                <Size
                    name={name}
                    value={innerDrawer.position_to_bottom}
                    metric="mm"
                    selectHandler={selectHandler}
                    isInvalid={innerDrawer?.isInvalid || false}
                    fieldSetIndex={runnerIndex}
                />
            </Col>
        </FormGroup>
    );
};
